<div
  class="row ml0 mr0 footer left-margin-lg"
  [ngClass]="{ 'primary-light-wrap': tenantId === 'sostereo' }"
  [ngStyle]="{
    'padding-bottom':
      deviceDetectorService.isMobile() || deviceDetectorService.isTablet() ? '190px' : '0'
  }"
>
  &copy;{{ currentYear }}
  <span *ngIf="environment.name !== 'sostereo' && tenantId !== 'sostereo'"
    >{{ tenantId | titlecase }} powered by
  </span>
  {{ environment.name | titlecase }}&trade;
  <span *ngIf="appVersion" class="pr20" style="font-size: 10px; float: right">{{
    appVersion
  }}</span>
</div>
