import { Component, Inject } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CommonService } from '../../../shared/services/common.service';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  public appVersion =
    'v' +
    this.environment.appVersion +
    (this.environment.buildNumber ? '-' + this.environment.buildNumber : '');

  public tenantId = this.commonService.tenantId;
  currentYear = new Date().getFullYear();

  constructor(
    @Inject('environment') public environment,
    private commonService: CommonService,
    public deviceDetectorService: DeviceDetectorService,
  ) {}
}
